import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Banner from "../components/banner"
import { Container, Row } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFire } from "@fortawesome/free-solid-svg-icons"

const WhyUs = () => (
  <>
    <Layout>
      <Seo title="Why Us" />
      <Banner title="Why Us" />
      <section id="services" className="single-features pt-5 pb-5">
        <Container>
          <Row className="justify-content-center">
            <div className="col-lg-10">
              <p className="pt-2 pb-2 lead">
                PCs/ Laptops/ Printers/ CCTVs are the center of all the small
                and big business ventures, however purchasing the same, will be
                highly expensive. Buying 2-3 Systems to getting them in dozens
                for your organization may increase your funds. Hence, lower down
                your finances by renting the IT equipment and grow your work
                area.
              </p>
              <h4 className="pt-2 pb-2">
                Benefits of Renting through Lappy Nation
              </h4>
              <p className="pt-2 pb-2 lead">
                <strong>
                  <FontAwesomeIcon icon={faFire} /> Availability:
                </strong>{" "}
                We are dealing with a wide range of PCs, Laptops, Printers,
                CCTVs from well-known brands such as Dell, Lenovo, HP, Apple,
                Samsung all in one store. With wide range of configuration of
                our products you will be benefitted.
              </p>
              <p className="pt-2 pb-2 lead">
                <strong>
                  <FontAwesomeIcon icon={faFire} /> Reliability:
                </strong>{" "}
                Lappy Nation worked with a group of qualified engineers and
                tests the system before transportation and installation and
                offer help for the span of the rental. We are close by to assist
                with any equipment inquiries or issues.
              </p>
              <p className="pt-2 pb-2 lead">
                <strong>
                  <FontAwesomeIcon icon={faFire} /> Proficiency:
                </strong>
                Best in service with a team of experts, we provide effortless
                services. We are there for you at the very corner from
                installations to assistance till the completion of your project.
                With a wide and exclusive range of IT rentals, we assure you to
                make your business functioning smooth and hassle-free.
              </p>
              <p className="pt-2 pb-2 lead">
                <strong>
                  <FontAwesomeIcon icon={faFire} /> Flexibility:
                </strong>
                We offer variable renting periods. One can rent from Lappy
                Nation for 1 Month/ 3 Months/ 6 months/ 9 months/ a Year and
                more. You may renew your rental after the completion of the
                period.
                <br />
                <small>*Minimum rental time period is of 1 month </small>
              </p>

              <p className="pt-2 pb-2 lead">
                <strong>
                  <FontAwesomeIcon icon={faFire} /> Easy Payments:
                </strong>
                One may easily rent our services by paying through multiple
                options as per their convenience such as payment can be done
                through, Cheque/ NEFT/ RTGS/ IMPS/ UPI/ Phonepe/ Paytm using
                Credit or Debit Cards.
              </p>
              <p className="pt-2 pb-2 lead">
                <strong> LAPPY NATION………Simply Convenient</strong>
              </p>

              <br />
            </div>
          </Row>
        </Container>
      </section>
    </Layout>
  </>
)

export default WhyUs
